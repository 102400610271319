import { Spinner, SpinnerSize } from '@fluentui/react';
import moment from 'moment';
import * as React from 'react';
import { connect } from 'react-redux';
import { FeatureFlagsState } from '../../../state/feature-flags';
import { IApplicationState } from '../../../state/index';
import { INonprofitState } from '../../../state/nonprofit';
import { ISearchState } from '../../../state/search';
import KeyValueText from '../../renderDocuments/KeyValueText';
import StatusIcon from '../../util/StatusIcon';
import { formatCityRegion } from '../../util/Utilities';
import '../Details.scss';

interface ISearchProps {
  nonprofitDetails?: INonprofitState;
  searchState: ISearchState;
  featureFlags: FeatureFlagsState;
}

const NonprofitDetail: React.FunctionComponent<ISearchProps> = ({ nonprofitDetails, searchState, featureFlags }) => (
  <div>
    {nonprofitDetails === undefined ||
    (nonprofitDetails.isNonprofitSearchResultsLoading === true && searchState.currentNonProfit === undefined) ? (
      <Spinner id="searchSpinner" size={SpinnerSize.large} label="Loading Nonprofit details..." ariaLive="assertive" />
    ) : (
      <section id="nonprofitDetails">
        {typeof nonprofitDetails.nonprofitDetail !== 'undefined' ? (
          <section>
            <section id="detailsSection">
              <div className="tile-content">
                <div className="detailsSectionHeader">
                  <section id="status">
                    <StatusIcon
                      status={
                        nonprofitDetails.additionalNonprofitInfo
                          ? nonprofitDetails.additionalNonprofitInfo.status
                          : nonprofitDetails.nonprofitDetail.status
                      }
                    />
                    <span className="boldText">
                      {nonprofitDetails.additionalNonprofitInfo
                        ? nonprofitDetails.additionalNonprofitInfo.nonprofitName
                        : nonprofitDetails.nonprofitDetail.name}
                    </span>
                  </section>
                </div>
                <div className="sectionDetails">
                  {nonprofitDetails.isNonprofitDetailLoading === true ? (
                    <Spinner id="addressSpinner" size={SpinnerSize.large} ariaLive="assertive" />
                  ) : (
                    <p className="addressRegion">
                      {nonprofitDetails.nonprofitDetail.address.addressLine1}
                      <br />
                      {formatCityRegion(nonprofitDetails.nonprofitDetail.address)}
                      <br />
                      {nonprofitDetails.nonprofitDetail.address.country}
                    </p>
                  )}
                  {
                    <section>
                      <KeyValueText
                        keyText="Agent Email"
                        valueText={
                          nonprofitDetails.additionalNonprofitInfo
                            ? nonprofitDetails.additionalNonprofitInfo.agentEmail
                            : ''
                        }
                      />
                      <KeyValueText
                        keyText="Domain(s)"
                        fieldName="domainFromDocuments"
                        listText={
                          nonprofitDetails.additionalNonprofitInfo
                            ? nonprofitDetails.additionalNonprofitInfo.domains
                            : ''
                        }
                      />
                      <KeyValueText
                        keyText="Effective Date"
                        valueText={
                          nonprofitDetails.additionalNonprofitInfo &&
                          nonprofitDetails.additionalNonprofitInfo.effectiveDateTime
                            ? new Date(nonprofitDetails.additionalNonprofitInfo.effectiveDateTime).toDateString()
                            : ''
                        }
                      />
                      <KeyValueText
                        keyText="Status"
                        boldText={true}
                        valueText={
                          nonprofitDetails.additionalNonprofitInfo
                            ? nonprofitDetails.additionalNonprofitInfo.status
                            : ''
                        }
                      />
                    </section>
                  }
                </div>
              </div>
            </section>
            <br />
            <section id="detailsSection">
              <div className="tile-content">
                <div className="sectionDetails">
                  <KeyValueText keyText="Nonprofit id" valueText={nonprofitDetails.nonprofitId} />
                  {
                    <section>
                      <KeyValueText
                        keyText="Master NonprofitId"
                        isHyperLink={true}
                        valueText={
                          nonprofitDetails.additionalNonprofitInfo
                            ? nonprofitDetails.additionalNonprofitInfo.masterNonprofitId
                            : ''
                        }
                      />
                      <KeyValueText
                        keyText="Tenant Id(s)"
                        fieldName="tenantIdFromDocuments"
                        listText={
                          nonprofitDetails.additionalNonprofitInfo
                            ? nonprofitDetails.additionalNonprofitInfo.tenants
                            : ''
                        }
                      />
                      <KeyValueText
                        keyText={
                          featureFlags.SupportToolProviderEnabled ? 'Transaction Id(s)' : 'TechSoup Transaction Id(s)'
                        }
                        fieldName="transactionIdsFromDocuments"
                        listText={
                          nonprofitDetails.additionalNonprofitInfo
                            ? nonprofitDetails.additionalNonprofitInfo.transactionIds
                            : ''
                        }
                      />
                      {featureFlags.SupportToolProviderEnabled ? (
                        <KeyValueText
                          keyText="Provider"
                          valueText={
                            nonprofitDetails.additionalNonprofitInfo
                              ? nonprofitDetails.additionalNonprofitInfo.eligibilityProvider
                              : ''
                          }
                        />
                      ) : (
                        <div>
                          <KeyValueText
                            keyText="TechSoup Org Id"
                            valueText={
                              nonprofitDetails.additionalNonprofitInfo
                                ? nonprofitDetails.additionalNonprofitInfo.techSoupOrgId
                                : ''
                            }
                          />
                          <KeyValueText
                            keyText="Activity Code"
                            valueText={
                              nonprofitDetails.additionalNonprofitInfo
                                ? nonprofitDetails.additionalNonprofitInfo.activityCode
                                : ''
                            }
                          />
                        </div>
                      )}
                      {nonprofitDetails.agentErrorResults && nonprofitDetails.agentErrorResults.count > 0 && (
                        <KeyValueText
                          keyText="Agent Error Count"
                          valueText={
                            nonprofitDetails.agentErrorResults
                              ? nonprofitDetails.agentErrorResults.count.toString()
                              : ''
                          }
                        />
                      )}
                      {nonprofitDetails.agentErrorResults && nonprofitDetails.agentErrorResults.count > 0 && (
                        <KeyValueText
                          keyText="Last Agent Error"
                          valueText={
                            nonprofitDetails.agentErrorResults
                              ? nonprofitDetails.agentErrorResults.lastErrorDateTime
                                ? `${moment(nonprofitDetails.agentErrorResults.lastErrorDateTime).fromNow()} (${moment(
                                    nonprofitDetails.agentErrorResults.lastErrorDateTime
                                  ).format('MM/DD/YYYY hh:mm A')})`
                                : ''
                              : ''
                          }
                        />
                      )}
                    </section>
                  }
                </div>
              </div>
            </section>
          </section>
        ) : (
          <section>
            <div className="detailsSectionHeader">
              <section id="status">
                <span className="boldText" />
              </section>
            </div>
            <div className="sectionDetails">
              {nonprofitDetails.isNonprofitDetailLoading === true ? (
                <Spinner
                  id="searchSpinner"
                  size={SpinnerSize.large}
                  label="Loading Nonprofit details..."
                  ariaLive="assertive"
                />
              ) : (
                <p />
              )}
            </div>
          </section>
        )}
      </section>
    )}
  </div>
);

const mapStateToProps = ({ nonprofit, search, flags }: IApplicationState) => ({
  nonprofitDetails: nonprofit,
  searchState: search,
  featureFlags: flags
});

export default connect(mapStateToProps)(NonprofitDetail);
