import { Reducer } from 'redux';
import { FeatureFlags } from '../../common/utilities/FeatureFlags';
import { FeatureFlagsActionTypes, FeatureFlagsState } from './types';

export const initialState: FeatureFlagsState = FeatureFlags;

const reducer: Reducer<FeatureFlagsState> = (state = initialState, action): FeatureFlagsState => {
  switch (action.type) {
    case FeatureFlagsActionTypes.FETCH_FLAGS: {
      return { ...state };
    }
    case FeatureFlagsActionTypes.SET_FLAGS: {
      const flags = action.payload as FeatureFlagsState;
      return { ...state, ...flags };
    }
    case FeatureFlagsActionTypes.SET_IS_LOADING: {
      return { ...state };
    }
    default: {
      return state;
    }
  }
};

export { reducer as featureFlagsReducer };
