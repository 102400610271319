export const enum FeatureFlagsActionTypes {
  FETCH_FLAGS = "@@feature-flags/FETCH_FLAGS",
  SET_FLAGS = "@@feature-flags/SET_FLAGS",
  SET_IS_LOADING = "@@feature-flags/SET_IS_LOADING"
}

// ADD NEW FEATURE FLAGS HERE
export interface FeatureFlagsState {
  readonly SupportToolProviderEnabled: boolean;
}
